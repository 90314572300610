import $ from 'jquery';
import 'jquery-plainmodal';

let $modal = null;

export const showModalError = (message) => { // eslint-disable-line import/prefer-default-export
  $modal = $(
    '<div class="modal-card bg-light col-xs-6" style="text-align: center;">' // eslint-disable-line
    + '<div class="card-body">'
    + '<p class="lead" style="color:black; font-weight:400">' + message + '</p>'
    + '<div><button class="btn btn-secondary btn-lg plainmodal-close" style="font-weight:400; padding-left: 40px; padding-right: 40px;">OK</button></div>'
    + '</div></div>',
  );
  $modal.plainModal('open');
};
