import $ from 'jquery';
import 'jquery-plainmodal';

let $modal = null;

export const showModalError = (message) => { // eslint-disable-line import/prefer-default-export
  $modal = $(
    '<div class="modal-card bg-light col-xs-6" style="text-align: center;">' // eslint-disable-line
    + '<div class="card-body">'
    + '<p class="lead" style="color:black; font-weight:400">' + message + '</p>'
    + '<div><button class="btn btn-secondary btn-lg plainmodal-close" style="font-weight:400; padding-left: 40px; padding-right: 40px;">OK</button></div>'
    + '</div></div>',
  );
  $modal.plainModal('open');
};

export const showCreatePaymentLinkModal = () => {
  // Select the modal element
  const modal = document.querySelector('.failed-ota-overlay');
  if (!modal) {
    return;
  }

  // Make the modal visible by removing 'display: none'
  modal.style.display = 'flex';

  // Select skip and close buttons
  const skipButton = modal.querySelector('#skip-btn');
  const closeButton = modal.querySelector('#close-btn');

  if (!skipButton || !closeButton) {
    return;
  }

  // Function to close the modal
  function closeModal() {
    modal.style.display = 'none'; // Hide the modal
  }

  // Attach event listeners to close and skip buttons
  closeButton.addEventListener('click', closeModal);
  skipButton.addEventListener('click', closeModal);

  // Event listener for clicking outside the modal content
  modal.addEventListener('click', (event) => {
    if (event.target === modal) {
      closeModal();
    }
  });

  // Close the modal when the Escape key is pressed
  document.addEventListener('keydown', (event) => {
    if (event.key === 'Escape') {
      closeModal();
    }
  });
};
